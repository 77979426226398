import React, { useMemo, useContext, useState } from "react";
import {
  useQueryParams,
  JsonParam,
  QueryParamConfig,
  withDefault,
  DelimitedArrayParam,
  NumberParam,
  StringParam,
} from "use-query-params";
import {
  Form,
  Col,
  Row,
  Alert,
  Collapse,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";

import {
  Periods,
  BHR_DEFENDER_COVERAGES,
  SCR_DEFENDER_COVERAGES,
  SCR_DEFENDER_DEPTH,
} from "../constants/AppConstants";
import AppContext from "../../shared/AppContext";
import { TeamContext } from "../TeamContext";
import { SSPlayerContext } from "../PlayerContext";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { SortingState } from "../components/core/Table";
import { Spinner } from "../components/core/Spinner";
import { MultiSelect } from "../components/core/MultiSelect";
import { ExpandCollapseButton } from "../components/core/ExpandCollapseButton";
import { VideoModal } from "../components/video/VideoModal";
import { PnrQueryResultsTable } from "../components/pnr/PnrQueryResultsTable_2";
import { trpc } from "../util/tRPC";
import { PnrAggregate, PnrFilters } from "../../shared/routers/PnrRouter_2";
import { NbaEaglePlayer } from "../../shared/routers/RosterRouter";
import { FilterForm } from "../components/query/FilterForm";
import { groupBy as arrGroupBy } from "../../shared/util/Collections";
import { pnrToSynergyEditorClip } from "../components/video/utilities";
import { ordinalFormat } from "../util/Format";

const RESULT_LIMIT = 10_000;

const RESULT_LIMIT_MSG = `Your query produced a large return set. To improve
performance we've limited the return data to ${RESULT_LIMIT.toLocaleString()}
rows.`;

const pnrGroupBys: { value: keyof PnrAggregate; label: string }[] = [
  { value: "season", label: "Season" },
  { value: "gameId", label: "Game" },
  { value: "offTeamId", label: "Offense Team" },
  { value: "defTeamId", label: "Defense Team" },
  { value: "ballhandlerId", label: "Ballhandler" },
  { value: "screenerId", label: "Screener" },
  { value: "ballhandlerDefenderId", label: "Ballhandler Defender" },
  { value: "screenerDefenderId", label: "Screener Defender" },
  {
    value: "period",
    label: "Period",
  },
  {
    value: "playoffs",
    label: "Playoffs",
  },
  { value: "bhrDefenderCoverage", label: "Ballhandler Defender Coverage" },
  { value: "scrDefenderCoverage", label: "Screener Defender Coverage" },
  { value: "scrDefenderDepth", label: "Screener Defender Depth" },
  { value: "type", label: "Type" },
  { value: "screenType", label: "Screen Type" },
  { value: "direction", label: "Direction" },
  { value: "floorSide", label: "Floor Side" },
  { value: "rollPop", label: "Roll/Pop" },
  { value: "pickSlip", label: "Pick/Slip" },
  { value: "numPerimSpacers", label: "# Perimeter Spacers" },
  { value: "numRimSpacers", label: "# Rim Spacers" },
  // { value: "double", label: "Double" },
  { value: "horns", label: "Horns" },
  { value: "drag", label: "Drag" },
  { value: "emptySide", label: "Empty Side" },
  { value: "dunkerFilled", label: "Dunker Filled" },
  { value: "fiveOut", label: "5-Out" },
  {
    value: "rollPop",
    label: "Roll / Pop",
  },
  {
    value: "direction",
    label: "Direction",
  },
  {
    value: "locationType",
    label: "Location Type",
  },
  {
    value: "screenType",
    label: "Screen Type",
  },
  // TODO(chrisbu): Add back when we include double screens again.
  // {
  //   value: "double",
  //   label: "Double",
  // },
  {
    value: "horns",
    label: "Horns",
  },
];

const SELECTABLE_COLUMNS: {
  value: keyof PnrAggregate;
  label: string;
  category: string;
}[] = [
  { value: "numPicks", label: "# PNRs", category: "General" },
  { value: "xppp", label: "xPPP", category: "General" },
  { value: "stderr", label: "SE", category: "General" },
  { value: "xpps", label: "xPPS", category: "General" },
  { value: "layupPct", label: "Layup %", category: "Shot Selection" },
  { value: "threePct", label: "3PA %", category: "Shot Selection" },
  { value: "nonLayupTwoPct", label: "NL2 %", category: "Shot Selection" },
  { value: "turnoverPct", label: "TO %", category: "Possession Outcome" },
  { value: "orbPct", label: "ORB %", category: "Possession Outcome" },
  { value: "xOrbPct", label: "xORB %", category: "Possession Outcome" },
  { value: "pnrPassPct", label: "Pass %", category: "Ballhandler Outcome" },
  {
    value: "pnrPassScrPct",
    label: "Pass Scr %",
    category: "Ballhandler Outcome",
  },
  {
    value: "pnrAstOppPct",
    label: "Ast Opp %",
    category: "Ballhandler Outcome",
  },
  {
    value: "pnrAstScrPct",
    label: "Ast Scr %",
    category: "Ballhandler Outcome",
  },
  { value: "pnrToPct", label: "TO %", category: "Ballhandler Outcome" },
  { value: "ppp", label: "PPP", category: "Actual" },
  { value: "pps", label: "PPS", category: "Actual" },
  { value: "layupFgPct", label: "Layup FG%", category: "Actual" },
  { value: "threeFgPct", label: "3P FG%", category: "Actual" },
  { value: "nonLayupTwoFgPct", label: "NL2 FG%", category: "Actual" },
];

export function PnrExplorerPage() {
  const [showColumnChooser, setShowColumnChooser] = useState(false);
  const [video, setVideo] = useState<PnrAggregate>();

  const [queryParams, setQueryParams] = useQueryParams({
    sorting: JsonParam as QueryParamConfig<SortingState>,
    groupBy: withDefault(DelimitedArrayParam, [
      "offTeamId",
    ]) as QueryParamConfig<(keyof PnrAggregate)[]>,
    filters: withDefault(JsonParam, {
      season: [AppContext.currentSeason],
      coverage: SCR_DEFENDER_COVERAGES.map((c) => c.value).filter(
        (c) => c !== "non-threatening"
      ),
    }) as QueryParamConfig<PnrFilters>,
    pnrThreshold: withDefault(NumberParam, 0),
    columns: withDefault(DelimitedArrayParam, [
      "numPicks",
      "xppp",
      "stderr",
    ]) as QueryParamConfig<(keyof PnrAggregate)[]>,
    level: withDefault(StringParam, "poss"),
  });

  const { groupBy, filters, sorting, pnrThreshold, columns, level } =
    queryParams;

  // Data to populate filters.
  const teams = useContext(TeamContext).teams;
  const players = useContext(SSPlayerContext);

  const playerSeasonTeamMap = useMemo(() => {
    if (!players) return;
    const ret: Record<string, Record<string, Set<string>>> = {};
    for (const player of players) {
      ret[player.playerId.toString()] = getSeasonTeamMap(player);
    }
    return ret;
  }, [players]);

  const filterPlayers = (p: NbaEaglePlayer, offense = true) => {
    const entry =
      playerSeasonTeamMap && playerSeasonTeamMap[p.playerId.toString()];
    if (!entry) return false;

    const seasons = filters.season;
    const teams = offense ? filters.offTeamId : filters.defTeamId;

    if (seasons?.length && teams?.length) {
      for (const season of seasons) {
        for (const team of teams) {
          const seasonEntry = entry[season];
          if (seasonEntry && seasonEntry.has(team)) {
            return true;
          }
        }
      }
      return false;
    } else if (seasons?.length) {
      return seasons.some((season) => Object.keys(entry).includes(season));
    } else if (teams?.length) {
      const allTeams = new Set(
        (function* () {
          for (const set of Object.values(entry)) yield* set;
        })()
      );
      return teams.some((team) => allTeams.has(team));
    }

    return true;
  };

  const { data: pnrs } = trpc.pnr2.getPnrAggregate.useQuery({
    groupBy: groupBy.join(","),
    filters,
    limit: RESULT_LIMIT,
  });

  function pnrToFilters(pnr: PnrAggregate) {
    const retObj: Record<string, unknown> = {};

    (Object.keys(pnr) as (keyof PnrAggregate)[]).forEach((key) => {
      switch (key) {
        // These are the "do nothing cases".
        case "gameDate":
        case "gameString":
        case "offTeam":
        case "defTeam":
        case "ballhandler":
        case "ballhandlerDefender":
        case "screener":
        case "screenerDefender":
          // These are the "do nothing cases" but they are group-by able.
          break;
        case "numPicks":
        case "xppp":
        case "xppp_action":
        case "stderr":
        case "stderr_action":
        case "xpps":
        case "xpps_action":
        case "layupPct":
        case "layupPct_action":
        case "threePct":
        case "threePct_action":
        case "nonLayupTwoPct":
        case "nonLayupTwoPct_action":
        case "turnoverPct":
        case "turnoverPct_action":
        case "orbPct":
        case "orbPct_action":
        case "xOrbPct":
        case "xOrbPct_action":
        case "pnrPassPct":
        case "pnrPassScrPct":
        case "pnrAstOppPct":
        case "pnrAstScrPct":
        case "pnrToPct":
        case "ppp":
        case "ppp_action":
        case "pps":
        case "pps_action":
        case "layupFgPct":
        case "layupFgPct_action":
        case "threeFgPct":
        case "threeFgPct_action":
        case "nonLayupTwoFgPct":
        case "nonLayupTwoFgPct_action":
        case "hasVideo":
          // TODO(chrisbu): Can we figure out the TS magic to narrow the type
          // downto only the PnrAggregate keys used in pnrGroupBys so that we
          // don't have all the skips here?
          break;
        case "season":
        case "gameId":
        case "period":
        case "offTeamId":
        case "defTeamId":
        case "ballhandlerId":
        case "ballhandlerDefenderId":
        case "screenerId":
        case "screenerDefenderId":
        case "type":
        case "screenType":
        case "direction":
        case "floorSide":
        case "rollPop":
        case "pickSlip":
        case "bhrDefenderCoverage":
        case "scrDefenderCoverage":
        case "scrDefenderDepth":
        case "locationType":
          // These are the multiselect cases.
          retObj[key] = [pnr[key] + ""];
          break;
        case "numPerimSpacers":
        case "numRimSpacers":
        case "playoffs":
        case "horns":
        case "drag":
        case "emptySide":
        case "dunkerFilled":
        case "fiveOut":
          // These are the booleans/single values;
          retObj[key] = pnr[key];
          break;
        default: {
          // Exhaustive check that makes sure we are handling all possible
          // group bys and won't compile if we are missing any.
          const exhaustiveCheck: never = key;
          throw new Error(`Unhandled key: ${exhaustiveCheck}`);
        }
      }
    });
    return retObj;
  }

  const videoFilters = { ...filters, ...(video ? pnrToFilters(video) : {}) };

  const { data: videoData } = trpc.pnr2.getPnrVideo.useQuery(
    {
      limit: RESULT_LIMIT,
      filters: { ...videoFilters },
    },
    { enabled: !!video }
  );

  const clips = videoData
    ? videoData
        .filter((vd) => vd.synergyURL)
        // TODO(chrisbu): Remove this shim when PNR explorer 2 is done.
        .map((vd) => {
          return {
            ...vd,
            synergyUrl: vd.synergyURL,
            oteam: vd.offTeam,
            dteam: vd.defTeam,
          };
        })
        .map(pnrToSynergyEditorClip)
    : [];

  const filteredPnrs = useMemo<PnrAggregate[]>(() => {
    return pnrs ? pnrs.filter((s) => s.numPicks >= pnrThreshold) : [];
  }, [pnrs, pnrThreshold]);

  const allData = pnrs ? pnrs : [];

  const msg = `Minimum PNRs: ${pnrThreshold.toLocaleString()}
  (showing ${filteredPnrs.length.toLocaleString()} of
  ${allData.length.toLocaleString()} total rows)`;

  return (
    <Page title="[WIP]: PNR Explorer" header={{ text: "[WIP]: PNR Explorer" }}>
      <>
        <Panel header={"Filters"}>
          <FilterForm
            filters={filters}
            setFilters={(f) => setQueryParams({ filters: f })}
            controls={[
              // Group 1 - Season/Period
              [
                // Row 1
                [
                  {
                    label: "Season",
                    key: "season",
                    type: "multiselect",
                    options: AppContext.seasons.map((s) => {
                      return { value: s.value.toString(), label: s.label };
                    }),
                  },
                  {
                    label: "Period",
                    key: "period",
                    type: "multiselect",
                    options: Object.keys(Periods).map((p) => {
                      return { value: p, label: Periods[parseInt(p)] || "" };
                    }),
                  },
                ],
              ],
              // Group 2 - Date/Playoffs
              [
                // Row 1
                [
                  {
                    label: "Date",
                    key: "dateFrom",
                    key2: "dateTo",
                    type: "daterange",
                  },
                  {
                    label: "Is Playoffs",
                    key: "playoffs",
                    type: "boolean",
                  },
                ],
                // Row 2
                [
                  {
                    label: "Shot Clock",
                    key: "shotClockFrom",
                    key2: "shotClockTo",
                    type: "range",
                    maxValue: 24,
                    stepSize: 1,
                    fmt: (v1: number, v2: number) => `${v1}s - ${v2}s`,
                  },
                  {
                    label: "Leverage",
                    key: "leverageFrom",
                    key2: "leverageTo",
                    type: "leverage",
                  },
                ],
              ],
              // Group 3 - Offense
              [
                // Row 1
                [
                  {
                    label: "Offense Team",
                    key: "offTeamId",
                    type: "multiselect",
                    options: teams.map((t) => {
                      return {
                        label: `${t.teamcity} ${t.teamname}`,
                        value: t.teamid.toString(),
                      };
                    }),
                  },
                ],
                // Row 2
                [
                  {
                    label: "Ballhandler",
                    key: "ballhandlerId",
                    type: "multiselect",
                    options: players
                      .filter((p) => filterPlayers(p, true))
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "Ballhandler Ability",
                    key: "ballhandlerPctlFrom",
                    key2: "ballhandlerPctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                ],
                // Row 3
                [
                  {
                    label: "Screener",
                    key: "screenerId",
                    type: "multiselect",
                    options: players
                      .filter((p) => filterPlayers(p, true))
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "Screener Ability",
                    key: "screenerPctlFrom",
                    key2: "screenerPctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                ],
              ],
              // Group 4 - Defense
              [
                // Row 1
                [
                  {
                    label: "Defense Team",
                    key: "defTeamId",
                    type: "multiselect",
                    options: teams.map((t) => {
                      return {
                        label: `${t.teamcity} ${t.teamname}`,
                        value: t.teamid.toString(),
                      };
                    }),
                  },
                ],
                // Row 2
                [
                  {
                    label: "Ballhandler Defender",
                    key: "ballhandlerDefenderId",
                    type: "multiselect",
                    options: players
                      .filter((p) => filterPlayers(p, false))
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "Ballhandler Defender Ability",
                    key: "ballhandlerDefenderPctlFrom",
                    key2: "ballhandlerDefenderPctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                ],
                // Row 3
                [
                  {
                    label: "Screener Defender",
                    key: "screenerDefenderId",
                    type: "multiselect",
                    options: players
                      .filter((p) => filterPlayers(p, false))
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "Screener Defender Ability",
                    key: "screenerDefenderPctlFrom",
                    key2: "screenerDefenderPctlTo",
                    type: "range",
                    maxValue: 1,
                    stepSize: 0.01,
                    fmt: pctlRangeFmt,
                  },
                ],
              ],
              [
                // Group 5 - Perimeter Spacers
                [
                  {
                    label: "Perimeter Spacer",
                    key: "perimeterSpacerId",
                    type: "multiselect",
                    options: players
                      .filter((p) => filterPlayers(p, true))
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "# Perimeter Spacers",
                    key: "fromNumPerimSpacers",
                    key2: "toNumPerimSpacers",
                    type: "range",
                    maxValue: 3,
                    stepSize: 1,
                    fmt: (v1: number, v2: number) =>
                      v1 === v2 ? `${v1} Spacers` : `${v1} - ${v2} Spacers`,
                  },
                ],
              ],
              // Group 6 - Rim Spacers
              [
                [
                  {
                    label: "Rim Spacer",
                    key: "rimSpacerId",
                    type: "multiselect",
                    options: players
                      .filter((p) => filterPlayers(p, true))
                      .map((p) => {
                        return {
                          label: p.player,
                          value: p.playerId.toString(),
                        };
                      }),
                  },
                  {
                    label: "# Rim Spacers",
                    key: "fromNumRimSpacers",
                    key2: "toNumRimSpacers",
                    type: "range",
                    maxValue: 3,
                    stepSize: 1,
                    fmt: (v1: number, v2: number) =>
                      v1 === v2 ? `${v1} Spacers` : `${v1} - ${v2} Spacers`,
                  },
                ],
              ],
              [
                // Group 7 - PNR Context
                [
                  {
                    label: "PNR/DHO",
                    key: "type",
                    type: "multiselect",
                    options: PNR_TYPES,
                  },
                  {
                    label: "Screen Type",
                    key: "screenType",
                    type: "multiselect",
                    options: SCREEN_TYPES,
                  },
                  {
                    label: "Direction",
                    key: "direction",
                    type: "multiselect",
                    options: [
                      { value: "right", label: "Right" },
                      { value: "left", label: "Left" },
                      { value: "none", label: "None" },
                    ],
                  },
                ],
                [
                  {
                    label: "Floor Side",
                    key: "floorSide",
                    type: "multiselect",
                    options: [
                      { value: "right", label: "Right" },
                      { value: "left", label: "Left" },
                      { value: "middle", label: "Middle" },
                    ],
                  },
                  {
                    label: "Roll/Pop",
                    key: "rollPop",
                    type: "multiselect",
                    options: [
                      {
                        value: "pop",
                        label: "Pop",
                      },
                      {
                        value: "roll",
                        label: "Roll",
                      },
                      {
                        value: "other",
                        label: "Other",
                      },
                    ],
                  },
                  {
                    label: "Pick/Slip",
                    key: "pickSlip",
                    type: "multiselect",
                    options: [
                      {
                        value: "pick",
                        label: "Pick",
                      },
                      {
                        value: "slip",
                        label: "Slip",
                      },
                    ],
                  },
                ],
              ],
              [
                // Group 8 - PNR Defense Context
                [
                  {
                    label: "Ballhandler Defender Coverage",
                    key: "bhrDefenderCoverage",
                    type: "multiselect",
                    options: BHR_DEFENDER_COVERAGES,
                  },
                  {
                    type: "blank",
                  },
                ],
                [
                  {
                    label: "Screener Defender Coverage",
                    key: "scrDefenderCoverage",
                    type: "multiselect",
                    options: SCR_DEFENDER_COVERAGES,
                  },
                  {
                    label: "Screener Defender Depth",
                    key: "scrDefenderDepth",
                    type: "multiselect",
                    options: SCR_DEFENDER_DEPTH,
                  },
                ],
              ],
              // Group 9 - PNR Booleans
              [
                // Row 1
                [
                  // TODO(chrisbu): Add back when we include double screens again.
                  // {
                  //   label: "Double",
                  //   key: "double",
                  //   type: "boolean",
                  // },
                  {
                    label: "Horns",
                    key: "horns",
                    type: "boolean",
                  },
                  {
                    label: "Drag",
                    key: "drag",
                    type: "boolean",
                  },
                  {
                    type: "blank",
                  },
                  {
                    type: "blank",
                  },
                ],
              ],
              // Group 10 - Spacing Booleans
              [
                // Row 1
                [
                  {
                    label: "Empty Side",
                    key: "emptySide",
                    type: "boolean",
                  },
                  {
                    label: "Dunker Filled",
                    key: "dunkerFilled",
                    type: "boolean",
                  },
                  {
                    label: "5-Out",
                    key: "fiveOut",
                    type: "boolean",
                  },
                  {
                    type: "blank",
                  },
                ],
              ],
            ]}
          />
          <Form.Group>
            <Form.Label>Group By</Form.Label>
            <MultiSelect
              values={pnrGroupBys}
              selected={groupBy}
              onChange={(g) =>
                setQueryParams({ groupBy: g as (keyof PnrAggregate)[] })
              }
            />
          </Form.Group>
          <Form style={{ marginTop: 12 }}>
            <div>
              <Form.Label>Select Columns</Form.Label>{" "}
              <ExpandCollapseButton
                onClick={() => setShowColumnChooser(!showColumnChooser)}
                expanded={showColumnChooser}
              />
            </div>
            <Collapse in={showColumnChooser}>
              <Row>
                {Object.entries(
                  arrGroupBy(SELECTABLE_COLUMNS, (x) => x.category)
                ).map(([cat, cols]) => {
                  return (
                    <Col key={cat} md={3}>
                      <div>
                        <Form.Label>{cat}</Form.Label>
                      </div>
                      {cols.map((sc) => {
                        return (
                          <Form.Check
                            type="checkbox"
                            key={sc.value}
                            inline
                            onChange={() => {
                              const idx = columns.findIndex(
                                (c) => c === sc.value
                              );
                              const newColumns = [...columns];
                              if (idx === -1) {
                                newColumns.push(sc.value);
                              } else {
                                // Else remove the item from the array.
                                newColumns.splice(idx, 1);
                              }
                              setQueryParams({ columns: newColumns });
                            }}
                            checked={columns.includes(sc.value)}
                            label={sc.label}
                          />
                        );
                      })}
                    </Col>
                  );
                })}
              </Row>
            </Collapse>
          </Form>
        </Panel>
        <Panel header={"Results"}>
          {pnrs ? (
            <div>
              {pnrs.length === RESULT_LIMIT
                ? showWarning(RESULT_LIMIT_MSG)
                : showSuccess(
                    `${pnrs.length.toLocaleString()} matching rows found.`
                  )}
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>{msg}</Form.Label>
                    <Form.Range
                      min={0}
                      max={1000}
                      value={pnrThreshold}
                      onChange={(evt) => {
                        setQueryParams({
                          pnrThreshold: parseInt(evt.target.value),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <ToggleButtonGroup
                    style={{ marginTop: 12 }}
                    name="action-level-toggle"
                    type="radio"
                    value={level}
                    onChange={(v) => setQueryParams({ level: v })}
                  >
                    <ToggleButton id="action-level-toggle-poss" value={"poss"}>
                      Possession Level
                    </ToggleButton>
                    <ToggleButton
                      id="action-level-toggle-action"
                      value={"action"}
                    >
                      Action Level
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Col>
              </Row>
              <PnrQueryResultsTable
                data={filteredPnrs}
                groupBy={groupBy}
                sorting={sorting}
                setSorting={(s) => setQueryParams({ sorting: s })}
                columns={columns}
                actionChanceLevel={level}
                onVideo={(data: PnrAggregate) => setVideo(data)}
              />
              <VideoModal
                title={"PNR Explorer"}
                show={!!video && !!clips.length}
                clips={clips}
                handleClose={() => setVideo(undefined)}
                upDownClipSkip={true}
                showSynergyEditor={true}
              />
            </div>
          ) : (
            <div>
              {groupBy.length === 0 ? (
                <Alert variant="danger">
                  At least one group by must be selected.
                </Alert>
              ) : (
                <Spinner />
              )}
            </div>
          )}
        </Panel>
      </>
    </Page>
  );
}

function showWarning(msg: string) {
  return <Alert variant="warning">{msg}</Alert>;
}

function showSuccess(msg: string) {
  return <Alert variant="success">{msg}</Alert>;
}

function getSeasonTeamMap(p: NbaEaglePlayer) {
  const map: Record<string, Set<string>> = {};
  const seasonTeamArr = p.seasonTeam.split(" ");
  for (const seasonTeam of seasonTeamArr) {
    const year = seasonTeam.split("_")[0] || "";
    const team = seasonTeam.split("_")[1] || "";
    const yearObj = map[year];
    if (!yearObj) {
      map[year] = new Set([team]);
    } else {
      yearObj.add(team);
    }
  }
  return map;
}

const PNR_TYPES = [
  { value: "pnr", label: "PNR" },
  { value: "dho", label: "DHO" },
  { value: "fake_dho", label: "Fake DHO" },
];

const SCREEN_TYPES = [
  { value: "angle/step-up", label: "Angle/Step-Up" },
  { value: "DHO", label: "DHO" },
  { value: "double", label: "Double" },
  { value: "inside arc", label: "Inside Arc" },
  { value: "low angle", label: "Low Angle" },
  { value: "middle", label: "Middle" },
  { value: "other", label: "Other" },
  { value: "side/wing", label: "Side/Wing" },
];

function pctlRangeFmt(v1: number, v2: number) {
  return v1 === v2
    ? `${ordinalFormat(Math.round(v1 * 100))} Percentile`
    : `${ordinalFormat(Math.round(v1 * 100))}  - ${ordinalFormat(
        Math.round(v2 * 100)
      )}  Percentile`;
}
