import React, { useState, useMemo } from "react";

import { PlayerEstPer100Box } from "../../../shared/routers/PlayerRouter";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import {
  dec100Format,
  decFormat,
  decFormat2,
  seasonString,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { PlayerTableCell, TeamTableCell } from "../core/TableCell";
import { trpc } from "../../util/tRPC";
import { per100 } from "../../constants/ColorDomains";

function hearStrForStat(header: string) {
  if (["PTS", "REB", "AST", "BLK", "STL"].includes(header)) {
    return `${header.slice(0, 1)}/100`;
  }
  return `${header}/100`;
}

const seasonBoxesHelper = createColumnHelper<PlayerEstPer100Box>();

export function PlayerCompareSeasonPer100EstBoxScores(props: {
  players: { playerId?: string; league?: string; seasons?: string[] }[];
}) {
  const { players } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const { data } = trpc.player.getPlayerEstPer100Boxes.useQuery({
    playerIds: players
      .map((p) => (p.playerId ? parseInt(p.playerId) : undefined))
      .filter((p) => !!p) as number[],
  });

  const playerData = (data || []).filter((pc) => {
    for (const p of players) {
      if (p.playerId === pc.celticsID.toString()) {
        if (p.league === undefined) {
          return true;
        }
        if (
          p.league ===
          (pc.league || "").toLocaleLowerCase().replaceAll(" ", "_")
        ) {
          if (p.seasons === undefined || p.seasons.length === 0) {
            return true;
          } else {
            return p.seasons.includes(pc.season.toString());
          }
        }
      }
    }
    return false;
  });

  const columns = useMemo(() => {
    let g = 0;

    return [
      seasonBoxesHelper.accessor("celticsID", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player}
          />
        ),
        meta: { group: g },
      }),
      seasonBoxesHelper.accessor("league", {
        header: () => "League",
        meta: { group: g, textAlign: "left" },
      }),
      seasonBoxesHelper.accessor("teamId", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell
            id={info.getValue() || undefined}
            ids={
              info.row.original.teamIds === null
                ? undefined
                : info.row.original.teamIds
            }
            league={
              info.row.original.league
                ? info.row.original.league
                    .toLocaleLowerCase()
                    .replaceAll(" ", "_")
                : ""
            }
            season={info.row.original.season.toString()}
            name={info.row.original.team || undefined}
          />
        ),
        meta: { group: g++ },
      }),
      seasonBoxesHelper.accessor("season", {
        header: () => "Season",
        cell: (info) => seasonString(info.getValue().toString()),
        meta: { group: g },
      }),
      seasonBoxesHelper.accessor("age", {
        header: () => "Age",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++ },
      }),
      // seasonBoxesHelper.accessor("g", {
      //   header: () => "G",
      //   meta: {
      //     group: g,
      //     highlights: Highlights.Max,
      //     colorDomain: per100["gp"),
      //   },
      // }),
      seasonBoxesHelper.accessor("min", {
        header: () => hearStrForStat("Min"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: per100["min"],
        },
      }),
      seasonBoxesHelper.accessor("pts", {
        header: () => hearStrForStat("PTS"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["pts"],
        },
      }),
      seasonBoxesHelper.accessor("ast", {
        header: () => hearStrForStat("AST"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["ast"],
        },
      }),
      seasonBoxesHelper.accessor("blk", {
        header: () => hearStrForStat("BLK"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["blk"],
        },
      }),
      seasonBoxesHelper.accessor("stl", {
        header: () => hearStrForStat("STL"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["stl"],
        },
      }),
      seasonBoxesHelper.accessor("tov", {
        header: () => hearStrForStat("TO"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Min,
          colorDomain: per100["to"],
        },
      }),
      seasonBoxesHelper.accessor("pf", {
        header: () => hearStrForStat("PF"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Min,
          colorDomain: per100["pf"],
        },
      }),
      seasonBoxesHelper.accessor("orPct", {
        header: () => "OR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["orbpct"],
        },
      }),
      seasonBoxesHelper.accessor("drPct", {
        header: () => "DR%",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: per100["drbpct"],
        },
      }),
      seasonBoxesHelper.accessor((row) => row.fga2 * row.fg2Pct, {
        id: "F2M",
        header: () => hearStrForStat("2PM"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["2pm"],
        },
      }),
      seasonBoxesHelper.accessor("fga2", {
        header: () => hearStrForStat("2PA"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["2pa"],
        },
      }),
      seasonBoxesHelper.accessor((row) => row.fga3 * row.fg3Pct, {
        id: "F3M",
        header: () => hearStrForStat("3PM"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["3pm"],
        },
      }),
      seasonBoxesHelper.accessor("fga3", {
        header: () => hearStrForStat("3PA"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["3pa"],
        },
      }),
      seasonBoxesHelper.accessor(
        (row) => row.fga2 * row.fg2Pct + row.fga3 * row.fg3Pct,
        {
          id: "FGM",
          header: () => hearStrForStat("FGM"),
          cell: (info) => decFormat(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: per100["fgm"],
          },
        }
      ),
      seasonBoxesHelper.accessor((row) => row.fga2 + row.fga3, {
        id: "FGA",
        header: () => hearStrForStat("FGA"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: per100["fga"],
        },
      }),
      seasonBoxesHelper.accessor((row) => row.fta * row.ftPct, {
        id: "FTM",
        header: () => hearStrForStat("FTM"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["ftm"],
        },
      }),
      seasonBoxesHelper.accessor("fta", {
        header: () => hearStrForStat("FTA"),
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["fta"],
        },
      }),
      seasonBoxesHelper.accessor("ftaPct", {
        header: () => "FTA Rate",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: per100["ftaRate"],
        },
      }),
      seasonBoxesHelper.accessor(
        (row) =>
          (row.fg2Pct * row.fga2 + row.fg3Pct * row.fga3) /
          (row.fga2 + row.fga3),
        {
          id: "FGPCT",
          header: () => "FG%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: per100["fgPct"],
          },
        }
      ),
      seasonBoxesHelper.accessor("fg2Pct", {
        header: () => "2P%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["2pct"],
        },
      }),
      seasonBoxesHelper.accessor("fg3Pct", {
        header: () => "3P%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["3pct"],
        },
      }),
      seasonBoxesHelper.accessor(
        (row) =>
          (row.fg2Pct * row.fga2 + 1.5 * row.fg3Pct * row.fga3) /
          (row.fga2 + row.fga3),
        {
          id: "eFGPCT",
          header: () => "eFG%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: per100["efg"],
          },
        }
      ),
      seasonBoxesHelper.accessor("ftPct", {
        header: () => "FT%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: per100["ftpct"],
        },
      }),
      seasonBoxesHelper.accessor("tovPct", {
        header: () => "TO%",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Min,
          colorDomain: per100["topct"],
        },
      }),
      seasonBoxesHelper.accessor("ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: per100["ppp"],
        },
      }),
      seasonBoxesHelper.accessor("usg", {
        header: () => "Usg",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: per100["usg"],
        },
      }),
    ];
  }, []);

  if (!playerData) return null;

  return (
    <Table
      data={playerData}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      showColorOnHover={true}
      autoWidth={true}
    />
  );
}
