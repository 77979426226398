import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { BooleanParam, useQueryParams, withDefault } from "use-query-params";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Col, Row, Form } from "react-bootstrap";
import { stringify } from "query-string";
import { encodeQueryParams, JsonParam } from "use-query-params";

import {
  PlayerBio,
  PlayerLastShots,
  PlayerSkillModelCombined,
} from "../../shared/routers/PlayerRouter";
import {
  PlayerImpact,
  PlayerImpactByPosition,
  PlayerImpactByRole,
} from "../../shared/routers/ImpactRouter";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Restrict } from "../components/core/Restrict";
import { PlayerBioHeader } from "../components/player/PlayerBioHeader";
import { PlayerContractSummary } from "../components/player/PlayerContractSummary";
import { PlayerMeasurementsTable } from "../components/player/PlayerMeasurementsTable";
import { PlayerMeasurementsBoxPlots } from "../components/player/PlayerMeasurementsBoxPlots";
import { PlayerImpactSeason } from "../components/player/PlayerImpactSeason";
import { PlayerSkillModelCombinedTable } from "../components/player/PlayerSkillModelCombinedTable";
import { PlayerMultiLeagueSeasonBox } from "../components/player/PlayerMultiLeagueSeasonBox";
import { PlayerEstimatedPer100BoxTable } from "../components/player/PlayerEstimatedPer100BoxTable";
import { PlayerMultiLeagueGameLogsTable } from "../components/player/PlayerMultiLeagueGameLogsTable";
import { PlayerSkillModelActionBreakdownGrouped } from "../components/player/PlayerSkillModelActionBreakdownGrouped";
import { PlayerScoutRatings } from "../components/player/PlayerScoutRatings";
import { PlayerSpeedByDateChart } from "../components/player/PlayerSpeedByDateChart";
import { ImpactScatterPlot } from "../components/impact/ImpactScatterPlot";
import { ImpactOverTimeChart } from "../components/impact/ImpactOverTimeChart";
import { PlayerInjuryTable } from "../components/player/PlayerInjuryTable";
import { PlayerShootingTable } from "../components/player/PlayerShootingTable";
import { ShotChart2D } from "../components/shots/ShotChart2D";
import { ShotChart1D } from "../components/shots/ShotChart1D";
import { DebugInfo } from "../components/core/DebugInfo";
import { playerByStatus } from "../util/Sort";
import { percentile } from "../util/Util";
import AppContext from "../../shared/AppContext";
import { CELTICS_TEAM_ID, complexShotTypeMap } from "../constants/AppConstants";
import { Spinner } from "../components/core/Spinner";
import { ShotQuality } from "../components/shots/ShotQuality";
import { Shot } from "../../shared/routers/ShotRouter";
import { PlayerDraftModelBreakdownTables } from "../components/player/PlayerDraftModelBreakdownTables";
import { PlayerGlobalImpactModelBreakdownTables } from "../components/player/PlayerGlobalImpactModelBreakdownTables";
import { PlayerDraftModelCompsTables } from "../components/player/PlayerDraftModelCompsTables";
import { PlayerPnrOffense } from "../components/player/PlayerPnrOffense";
import { PlayerDrivesTable } from "../components/player/PlayerDrivesTable";
import { PlayerPostUpsTable } from "../components/player/PlayerPostUpsTable";
import { PlayerRoleImpact } from "../components/player/PlayerRoleImpact";
import { PlayerSpeedTable } from "../components/player/PlayerSpeedTable";
import { PlayerSynergySeasonBox } from "../components/player/PlayerSynergySeasonBox";
import { useWindowSize } from "../util/Hooks";
import { PlayerDriveChart } from "../components/player/PlayerDriveChart";
import { trpc } from "../util/tRPC";
import {
  PlayerModeledMeasurements,
  PlayerMeasurements,
  PlayerMultiLeagueGameLogs,
} from "../../shared/routers/PlayerRouter";
import { PlayerSkillBreakdownTables } from "../components/player/PlayerSkillBreakdownTables";
import {
  DxRankingOverTime,
  TypicalDraftModelRankingOverTime,
} from "../../shared/routers/DraftRouter";
import { DxRankingOverTimeChart } from "../components/draft/DxRankingOverTimeChart";
import { UserContext } from "../UserContext";
import { RimChart } from "../components/shots/RimChart";

const useStyles = makeStyles(() =>
  createStyles({
    headerLeftRightContainer: {
      /** Clearfix. */
      overflow: "auto",
      minHeight: 154,
    },
    headerLeft: {
      float: "left",
    },
    headerRight: {
      marginTop: 10,
      float: "right",
      textAlign: "right",
      ["@media (max-width:768px)"]: {
        float: "none",
        textAlign: "center",
        marginLeft: 0,
      },
    },
    toggleCheckbox: {
      fontWeight: 400,
      fontSize: ".9em",
    },
    controlContainer: { display: "flex", gap: 10, alignItems: "center" },
    selector: { width: "auto", display: "inline" },
  })
);

export function PlayerPage() {
  const classes = useStyles();
  const { id } = useParams();

  const [queryParams, setQueryParams] = useQueryParams({
    measurementsPlotsVisible: withDefault(BooleanParam, false),
  });

  const { measurementsPlotsVisible } = queryParams;
  const setMeasurementsPlotsVisible = (visible: boolean) => {
    setQueryParams({ measurementsPlotsVisible: visible });
  };

  // The active tab for the impact and skill panel. It is lifted out of the
  // subcomponent so that we can manipulate state. Specifically we want to
  // be able to link directly into the impact by role subpanel.
  const [impactAndSkillPanelActiveTab, setImpactAndSkillPanelActiveTab] =
    useState("impactSeason");
  // Reset the active tab when the player id changes.
  useEffect(() => {
    setImpactAndSkillPanelActiveTab("impactSeason");
  }, [id]);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  const { data: bioData } = trpc.player.getPlayerBios.useQuery({
    playerIds: id ? [id] : [],
  });
  const bio = bioData && bioData.length > 0 ? bioData[0] : undefined;

  const { data: draftModel } = trpc.player.getPlayerDratModel.useQuery({
    playerId: id ? parseInt(id) : undefined,
  });

  const { data: modeledMeasurements } =
    trpc.player.getPlayerModeledMeasurements.useQuery({
      playerIds: id ? [id] : [],
    });

  const { data: measurements } = trpc.player.getPlayerMeasurements.useQuery({
    playerIds: id ? [id] : [],
  });

  const { data: impact } = trpc.impact.getPlayerImpact.useQuery({
    playerId: id ? parseInt(id) : undefined,
  });

  const { data: impactByRole } = trpc.impact.getPlayerImpactByRole.useQuery({
    playerId: id ? parseInt(id) : undefined,
  });

  const { data: impactByPos } = trpc.impact.getPlayerImpactByPosition.useQuery({
    playerId: id ? parseInt(id) : undefined,
  });

  const { data: multiLeagueGameLogs } =
    trpc.player.getPlayerMultiLeagueGameLogs.useQuery({
      playerId: id ? parseInt(id) : undefined,
    });

  const { data: skillModelCombined } =
    trpc.player.getPlayerSkillModelCombined.useQuery({
      playerId: id ? parseInt(id) : undefined,
    });

  const { data: dxRankingOverTime } =
    trpc.draft.getPlayerDxRankingOverTime.useQuery({
      playerId: id ? parseInt(id) : undefined,
    });

  const { data: draftModelRankOverTime } =
    trpc.draft.getPlayerTypicalDraftModelRankOverTime.useQuery({
      playerId: id ? parseInt(id) : undefined,
    });

  const { data: shotChart1d } = trpc.player.getPlayerShotChart1d.useQuery({
    playerId: id ? parseInt(id) : undefined,
  });

  const { data: shotChart1dRug } = trpc.player.getPlayerShotChart1dRug.useQuery(
    {
      playerId: id ? parseInt(id) : undefined,
    }
  );

  const { data: shotChart1dSynergy } =
    trpc.player.getPlayerShotChart1d.useQuery({
      playerId: id ? parseInt(id) : undefined,
      synergy: true,
    });

  const { data: shotChart1dRugSynergy } =
    trpc.player.getPlayerShotChart1dRug.useQuery({
      playerId: id ? parseInt(id) : undefined,
      synergy: true,
    });

  const { data: shotChart2d } = trpc.player.getPlayerShotChart2d.useQuery({
    playerId: id ? parseInt(id) : undefined,
  });
  const { data: shotChart2dSynergy } =
    trpc.player.getPlayerShotChart2d.useQuery({
      playerId: id ? parseInt(id) : undefined,
      synergy: true,
    });

  // Grab a list of all current players to find teammates.
  const { data: allPlayers } = trpc.roster.getSeasonRosters.useQuery();
  const teamRoster = (allPlayers || [])
    .filter((ap) => bio !== undefined && ap.teamid === bio.teamid)
    .sort(playerByStatus);

  const { data: shotChart1dLeague } =
    trpc.player.getPlayerShotChart1dLeague.useQuery();

  const { data: lastShots } = trpc.player.getPlayerLastShots.useQuery({
    playerId: id ? parseInt(id) : undefined,
    numGames: 110,
  });

  if (!bio) return null;

  // When the impact by role breakdown is clicked in the header take the user to
  // the impact by role tab.
  const scrollToImpactByRole = () => {
    const el = document.querySelector("#impact");
    if (!el) return;
    const yOffset = -76; // The navbar + team selector.
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
    setImpactAndSkillPanelActiveTab("impactByRole");
  };

  const scrollToGlobalImpactEstimate = (tab: string) => {
    const el = document.querySelector("#impact");
    if (!el) return;
    const yOffset = -76; // The navbar + team selector.
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
    setImpactAndSkillPanelActiveTab(tab);
  };

  const renderPageHeader = () => {
    return (
      <div className={classes.headerLeftRightContainer}>
        <div className={classes.headerLeft}>
          <PlayerBioHeader
            bio={bio}
            teamRoster={teamRoster}
            measurements={measurements}
            modeledMeasurements={modeledMeasurements}
            draftModel={draftModel}
            handleImpactBreakdownClick={scrollToImpactByRole}
            handleGlobalImpactEstimateClick={scrollToGlobalImpactEstimate}
          />
        </div>
        {id && (
          <div className={classes.headerRight}>
            <div className="group">
              <PlayerContractSummary playerId={parseInt(id)} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const playerId = bio.playerId;

  const nbaPlayer = !!bio.idEagle;

  const show1dShotChart =
    (shotChart1d && shotChart1d.length > 0) ||
    (shotChart1dSynergy && shotChart1dSynergy.length > 0);
  const show2dShotChart =
    (shotChart2d && shotChart2d.length > 0) ||
    (shotChart2dSynergy && shotChart2dSynergy.length > 0);

  return (
    <Page title={bio.fullName} header={{ component: renderPageHeader() }}>
      <>
        {isMobile && (
          <Row>
            <Col xs={12}>
              <BoxScoresPanel
                playerId={playerId}
                multiLeagueGameLogs={multiLeagueGameLogs || []}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Restrict roles={["bia"]} override={!!bio.showOnTop}>
            {nbaPlayer && (
              <Col md={3} sm={5} xs={12}>
                <ImpactScatterPlotPanel playerId={playerId} />
              </Col>
            )}
            <Col md={3} sm={5} xs={12}>
              <ImpactOverTimePanel
                playerId={playerId}
                yearsToUseCamera={new Set((impact || []).map((i) => i.Season))}
              />
            </Col>
          </Restrict>
          {show1dShotChart && (
            <Col md={3} sm={5} xs={12}>
              <Panel
                header={`1D Shot Chart ${
                  shotChart1d && shotChart1d.length === 0
                    ? "(SYNERGY DATA)"
                    : ""
                }`}
              >
                {shotChart1d &&
                  shotChart1dSynergy &&
                  shotChart1dLeague &&
                  shotChart1dRug &&
                  shotChart1dRugSynergy && (
                    <ShotChart1D
                      data={
                        shotChart1d.length === 0
                          ? shotChart1dSynergy
                          : shotChart1d
                      }
                      isSynergy={shotChart1d.length ? false : true}
                      league={shotChart1dLeague}
                      rug={
                        shotChart1d.length
                          ? shotChart1dRug
                          : shotChart1dRugSynergy
                      }
                    />
                  )}
              </Panel>
            </Col>
          )}
          {show2dShotChart && (
            <Col md={3} sm={5} xs={12}>
              <Panel
                key={playerId}
                header={`2D Shot Chart ${
                  shotChart2d && shotChart2d.length === 0
                    ? "(SYNERGY DATA)"
                    : ""
                }`}
                padding="thin"
              >
                {shotChart2d && shotChart2dSynergy && (
                  <ShotChart2D
                    data={shotChart2d.length ? shotChart2d : shotChart2dSynergy}
                    isSynergy={shotChart2d.length ? false : true}
                  />
                )}
              </Panel>
            </Col>
          )}
        </Row>
        <Row>
          {!isMobile && (
            <Col xs={12}>
              <BoxScoresPanel
                playerId={playerId}
                multiLeagueGameLogs={multiLeagueGameLogs || []}
              />
            </Col>
          )}
          <Col md={12}>
            {measurements && (
              <ScoutRatingsPanel bio={bio} measurements={measurements} />
            )}
          </Col>
          <Col>
            {measurements && modeledMeasurements && (
              <MeasurementsPanel
                bio={bio}
                measurements={measurements}
                modeledMeasurements={modeledMeasurements}
                multiLeagueGameLogs={multiLeagueGameLogs || []}
                measurementsPlotsVisible={measurementsPlotsVisible}
                setMeasurementsPlotsVisible={setMeasurementsPlotsVisible}
              />
            )}
          </Col>
          {nbaPlayer && (
            <Col xs={12}>
              <Panel header="Shooting Details">
                <PlayerShootingTable playerId={playerId} />
              </Panel>
            </Col>
          )}
          <Col md={12}>
            {impact &&
              skillModelCombined &&
              impactByRole &&
              impactByPos &&
              // Make sure *something* has data.
              impact.length +
                skillModelCombined.length +
                impactByRole.length +
                impactByPos.length >
                0 && (
                <ImpactAndSkillPanel
                  impact={impact}
                  impactByRole={impactByRole}
                  impactByPos={impactByPos}
                  skillModelCombined={skillModelCombined}
                  impactAndSkillPanelActiveTab={impactAndSkillPanelActiveTab}
                  setImpactAndSkillPanelActiveTab={
                    setImpactAndSkillPanelActiveTab
                  }
                  showCelticsRoles={bio.teamid !== CELTICS_TEAM_ID}
                  dxRankingOverTime={dxRankingOverTime}
                  draftModelRankOverTime={draftModelRankOverTime}
                  bio={bio}
                />
              )}
          </Col>
          <Col md={12}>
            <ActionsBreakdownPanel playerId={playerId} />
          </Col>
          {nbaPlayer && (
            <Col lg={8} md={8} sm={12}>
              <PNROffensePanel playerId={playerId} />
              <Panel header="Drives">
                <PlayerDrivesTable playerId={playerId} />
              </Panel>
              <Panel header="Post Ups">
                <PlayerPostUpsTable playerId={playerId} />
              </Panel>
            </Col>
          )}
          {nbaPlayer && bio.idEagle && (
            <Col lg={4} md={4} sm={6}>
              <ShotQualityPanel shots={lastShots} teamId={bio.teamid} />
              <Panel header="Drive Chart" padding="thin">
                <PlayerDriveChart playerIdEagle={bio.idEagle} />
              </Panel>
            </Col>
          )}
          {nbaPlayer && (
            <>
              <Col lg={8} md={8} sm={6}>
                <InjuriesPanel playerId={playerId} />
              </Col>
              <Restrict roles={["bia"]}>
                <Col lg={4} md={4} sm={6}>
                  <Panel header="[BIA ONLY] - Rim Chart">
                    <RimChart shots={lastShots} />
                  </Panel>
                </Col>
              </Restrict>
            </>
          )}
          <Col md={6}>
            <SynergySeasonBoxScoresPanel playerId={playerId} />
          </Col>
          <Col md={6}>
            <SpeedPanel playerId={playerId} />
          </Col>
          <Col xs={12}>
            <PlayerLinksPanel playerId={playerId} />
          </Col>
          <Col xs={12}>
            <DebugInfo playerId={playerId} />
          </Col>
        </Row>
      </>
    </Page>
  );
}

function ImpactScatterPlotPanel(props: { playerId: number }) {
  const playerId = props.playerId;

  const { data: leagueImpact } = trpc.impact.getLeagueImpact.useQuery({});

  // If we don't have an impact value for the player don't render anything.
  if (!leagueImpact || !leagueImpact.some((li) => li.playerId === playerId))
    return null;

  return (
    <Panel header="Impact" padding="thin">
      <ImpactScatterPlot data={leagueImpact} focus={playerId} />
    </Panel>
  );
}

function ImpactOverTimePanel(props: {
  playerId: number;
  yearsToUseCamera: Set<number>;
}) {
  const [impactPercentiles, setImpactPercentiles] = useState<number[]>();
  const { playerId, yearsToUseCamera } = props;

  const { data: impact } = trpc.impact.getPlayerImpactOverTime.useQuery({
    playerId: playerId.toString(),
  });

  const { data: globalImpact } =
    trpc.impact.getPlayerGlobalImpactOverTime.useQuery({
      playerId: playerId.toString(),
    });

  trpc.impact.getLeagueImpact.useQuery(
    {},
    {
      onSuccess: (res) => {
        const data = res.map((d) => d.netImpact).sort((a, b) => a - b);
        setImpactPercentiles([
          percentile(data, 0.05),
          percentile(data, 0.25),
          percentile(data, 0.5),
          percentile(data, 0.75),
          percentile(data, 0.95),
        ]);
      },
    }
  );

  if (
    !impact ||
    !impactPercentiles ||
    !globalImpact ||
    globalImpact.length === 0
  )
    return null;

  let title = "Impact Over Time";
  // If there is no camera impact indicate that.
  if (impact.length === 0) {
    title += " (Global Estimate)";
  }

  return (
    <Panel header={title} padding="thin">
      <ImpactOverTimeChart
        yearsToUseCamera={yearsToUseCamera}
        globalImpact={globalImpact}
        cameraImpact={impact}
        percentiles={impactPercentiles}
        playerId={playerId}
      />
    </Panel>
  );
}

function BoxScoresPanel(props: {
  playerId: number;
  multiLeagueGameLogs: PlayerMultiLeagueGameLogs[];
}) {
  const [boxScoresTab, setBoxScoresTab] = useState("multiLeagueSeasonBox");
  const { playerId, multiLeagueGameLogs } = props;

  const { data: boxes } = trpc.player.getPlayerMultiLeagueSeasonBoxes.useQuery({
    playerId,
  });

  const { data: per100EstBoxes } = trpc.player.getPlayerEstPer100Boxes.useQuery(
    { playerIds: [playerId] }
  );

  const dataReady = !!(
    boxes &&
    boxes.career.length &&
    boxes.season.length &&
    per100EstBoxes
  );

  const tabs = {
    onClick: (tabKey: string) => setBoxScoresTab(tabKey),
    active: boxScoresTab,
    tabs: {
      multiLeagueSeasonBox: {
        label: "Season Box Scores",
        content: (
          <div>
            {dataReady && (
              <PlayerMultiLeagueSeasonBox data={boxes} type="perGame" />
            )}
          </div>
        ),
      },
      multiLeagueSeasonBoxPer100: {
        label: "Per 100 Possessions",
        content: (
          <div>
            {dataReady && (
              <PlayerMultiLeagueSeasonBox data={boxes} type="per100Poss" />
            )}
          </div>
        ),
      },
      multiLeagueSeasonBoxPer100Est: {
        label: "Per 100 Possessions Estimates",
        content: (
          <div>
            {dataReady && (
              <PlayerEstimatedPer100BoxTable data={per100EstBoxes} />
            )}
          </div>
        ),
      },
      multiLeagueSeasonTotals: {
        label: "Season Totals",
        content: (
          <div>
            {dataReady && (
              <PlayerMultiLeagueSeasonBox data={boxes} type="totals" />
            )}
          </div>
        ),
      },
      multiLeagueGameLogs: {
        label: "Game Logs",
        content: (
          <div>
            {multiLeagueGameLogs.length > 0 && (
              <PlayerMultiLeagueGameLogsTable
                multiLeagueGameLogs={multiLeagueGameLogs}
              />
            )}
          </div>
        ),
      },
    },
  };

  return <Panel tabs={tabs}></Panel>;
}

function ScoutRatingsPanel(props: {
  bio: PlayerBio;
  measurements: PlayerMeasurements[];
}) {
  const user = useContext(UserContext);
  const { bio, measurements } = props;

  const { data: ratings } = trpc.scouting.getScoutRatings.useQuery({
    user: user ? user.email.split("@")[0] || "unknown" : "unknown",
    playerIds: [bio.playerId.toString()],
  });

  if (!ratings) return null;

  return (
    <Panel header="My Scout Ratings">
      <PlayerScoutRatings
        key={bio.playerId}
        data={ratings}
        bio={bio}
        measurements={measurements}
      />
    </Panel>
  );
}

function MeasurementsPanel(props: {
  bio: PlayerBio;
  measurements: PlayerMeasurements[];
  modeledMeasurements: PlayerModeledMeasurements[];
  multiLeagueGameLogs: PlayerMultiLeagueGameLogs[];
  measurementsPlotsVisible: boolean;
  setMeasurementsPlotsVisible: (visible: boolean) => void;
}) {
  const classes = useStyles();
  const {
    bio,
    measurements,
    modeledMeasurements,
    measurementsPlotsVisible,
    setMeasurementsPlotsVisible,
  } = props;

  const playerId = bio.playerId;
  const position = bio.position;

  if (measurements.length === 0 && modeledMeasurements.length === 0) {
    return null;
  }

  let measurementsPlots = null;
  if (measurementsPlotsVisible) {
    measurementsPlots = (
      <PlayerMeasurementsBoxPlots measurements={modeledMeasurements} />
    );
  }

  const compareLink = `/compare-players?${stringify(
    encodeQueryParams(
      { players: JsonParam },
      { players: [{ playerId: playerId.toString() }] }
    )
  )}`;

  return (
    <Panel header="Measurements">
      <div>
        <PlayerMeasurementsTable
          data={measurements}
          modeled={modeledMeasurements}
          position={position ? parseInt(position) : 0}
          age={bio.age}
        />
        <label className={classes.toggleCheckbox}>
          <input
            type="checkbox"
            checked={measurementsPlotsVisible}
            onChange={() =>
              setMeasurementsPlotsVisible(!measurementsPlotsVisible)
            }
          />
          {
            <span>
              {" Show Plots"}
              {" | "}
              <a href={compareLink}>Compare</a>
            </span>
          }
        </label>
        {measurementsPlots}
      </div>
    </Panel>
  );
}

function ImpactAndSkillPanel(props: {
  impact: PlayerImpact[];
  skillModelCombined: PlayerSkillModelCombined[];
  impactByRole: PlayerImpactByRole[];
  impactByPos: PlayerImpactByPosition[];
  impactAndSkillPanelActiveTab: string;
  setImpactAndSkillPanelActiveTab: (tab: string) => void;
  showCelticsRoles: boolean;
  dxRankingOverTime: DxRankingOverTime[] | undefined;
  draftModelRankOverTime: TypicalDraftModelRankingOverTime[] | undefined;
  bio: PlayerBio;
}) {
  const {
    skillModelCombined,
    impact,
    impactByRole,
    impactByPos,
    impactAndSkillPanelActiveTab,
    setImpactAndSkillPanelActiveTab,
    showCelticsRoles,
    dxRankingOverTime,
    draftModelRankOverTime,
    bio,
  } = props;

  // If we don't have impact numbers for a given player make sure we don't have
  // impact set as active tab.
  if (impact.length === 0 && impactAndSkillPanelActiveTab === "impactSeason") {
    setImpactAndSkillPanelActiveTab("skillModelCombinedSeason");
  }

  const user = useContext(UserContext);
  const isBia = user && user.group === "bia";

  const impactSkillTabs = {
    onClick: (tabKey: string) => setImpactAndSkillPanelActiveTab(tabKey),
    active: impactAndSkillPanelActiveTab,
    tabs: {
      impactSeason: {
        label: "Impact",
        content: <PlayerImpactSeason data={impact} />,
        hidden: impact.length === 0,
      },
      impactByRole: {
        label: "Impact By Role",
        content: (
          <PlayerRoleImpact
            impactByRole={impactByRole}
            impactByPos={impactByPos}
            showCelticsRoles={showCelticsRoles}
          />
        ),
        hidden: impactByRole.length === 0,
      },
      skillModelCombinedSeason: {
        label: "GLOBAL IMPACT ESTIMATES",
        content: (
          <PlayerSkillModelCombinedTable
            data={skillModelCombined.filter((x) => x.source === "season")}
            type="season"
          />
        ),
        hidden: skillModelCombined.length === 0,
      },
      skillModelCombinedPeak: {
        label: "DRAFT MODEL",
        content: (
          <PlayerSkillModelCombinedTable
            data={skillModelCombined.filter((x) => x.source === "peak")}
            type="peak"
          />
        ),
        hidden: skillModelCombined.length === 0,
      },
      historicalDxRanking: {
        label: "[BIA ONLY] Historical DX Ranking",
        content: (
          <DxRankingOverTimeChart
            data={dxRankingOverTime}
            draftModel={draftModelRankOverTime}
            bio={bio}
          />
        ),
        // Don't show this if we have no data OR we only have one data point.
        hidden:
          !(isBia || (user && user.email === "psexton@celtics.com")) ||
          dxRankingOverTime === undefined ||
          dxRankingOverTime.length < 2,
      },
    },
  };

  return <Panel tabs={impactSkillTabs} panelID="impact" />;
}

function ActionsBreakdownPanel(props: { playerId: number }) {
  const classes = useStyles();
  const { playerId } = props;

  const [actionsBreakdownTab, setActionsBreakdownTab] = useState(
    "groupedSkillActionSimpleModel"
  );
  const [showPercentile, setShowPercentile] = useState(false);
  const defaultSeason = AppContext.secondSpectrumSeasons[0] || {
    value: 0,
    label: "Error",
  };

  const [season, setSeason] = useState(defaultSeason.value.toString());

  const { data: actionDataSimple } =
    trpc.player.getPlayerSkillModelActionSimpleBreakdown.useQuery({
      playerId,
      season,
    });
  const { data: actionData } =
    trpc.player.getPlayerSkillModelActionBreakdown.useQuery({
      playerId,
      season,
    });
  const { data: draftModelYears } =
    trpc.draft.getPlayerDraftModelYears.useQuery({
      playerId,
    });

  const { data: draftModelCompYears } =
    trpc.draft.getPlayerDraftModelCompYears.useQuery({
      playerId,
    });

  const { data: skillBreakdown } = trpc.impact.getPlayerSkillBreakdown.useQuery(
    {
      playerId,
    }
  );

  const tabs = {
    onClick: (tabKey: string) => setActionsBreakdownTab(tabKey),
    active: actionsBreakdownTab,
    tabs: {
      groupedSkillActionSimpleModel: {
        label: "Action Simple",
        content: (
          <div>
            <div className={classes.controlContainer}>
              <Form.Label>Season</Form.Label>
              <Form.Select
                value={season}
                className={classes.selector}
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                  setSeason(evt.target.value);
                }}
              >
                {AppContext.secondSpectrumSeasons.map((s) => {
                  return (
                    <option key={s.value} value={s.value.toString()}>
                      {s.label}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Check
                label="Show Percentile"
                checked={showPercentile}
                onChange={() => {
                  setShowPercentile(!showPercentile);
                }}
              />
            </div>
            {!actionDataSimple && <Spinner />}
            {actionDataSimple && (
              <PlayerSkillModelActionBreakdownGrouped
                data={actionDataSimple}
                showPercentile={showPercentile}
                isSimple={true}
              />
            )}
          </div>
        ),
      },
      groupedSkillActionModel: {
        label: "Action Grouped",
        content: (
          <div>
            <div className={classes.controlContainer}>
              <Form.Label>Season</Form.Label>
              <Form.Select
                value={season}
                className={classes.selector}
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                  setSeason(evt.target.value);
                }}
              >
                {AppContext.secondSpectrumSeasons.map((s) => {
                  return (
                    <option key={s.value} value={s.value.toString()}>
                      {s.label}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Check
                label="Show Percentile"
                checked={showPercentile}
                onChange={() => {
                  setShowPercentile(!showPercentile);
                }}
              />
            </div>
            {!actionData && <Spinner />}
            {actionData && (
              <PlayerSkillModelActionBreakdownGrouped
                data={actionData}
                showPercentile={showPercentile}
                isSimple={false}
              />
            )}
          </div>
        ),
      },
      skillBreakdown: {
        label: "Skill Breakdown",
        content: (
          <div>
            {skillBreakdown && (
              <PlayerSkillBreakdownTables data={skillBreakdown} />
            )}
          </div>
        ),
      },
      breakdownDraftModel: {
        label: "Draft Model Breakdown",
        content: (
          <div>
            {draftModelYears && (
              <PlayerDraftModelBreakdownTables
                playerId={playerId}
                draftModelYears={draftModelYears}
              />
            )}
          </div>
        ),
      },
      globalImpactModel: {
        label: "Global Impact Model Breakdown",
        content: (
          <div>
            {draftModelYears && (
              <PlayerGlobalImpactModelBreakdownTables playerId={playerId} />
            )}
          </div>
        ),
      },
      draftModelComps: {
        label: "Draft Model Comps",
        content: (
          <div>
            {draftModelCompYears && (
              <PlayerDraftModelCompsTables
                playerId={playerId}
                draftModelYears={draftModelCompYears}
              />
            )}
          </div>
        ),
      },
    },
  };

  return <Panel tabs={tabs}></Panel>;
}

function PNROffensePanel(props: { playerId: number }) {
  const { playerId } = props;
  return (
    <Panel header="PNR Offense">
      <PlayerPnrOffense playerId={playerId} />
    </Panel>
  );
}

function ShotQualityPanel(props: {
  teamId: number | null;
  shots: PlayerLastShots[] | undefined;
}) {
  const { shots, teamId } = props;
  const [games, setGames] = useState("lastGame");
  const [shotType, setShotType] = useState("all");

  if (!shots || shots.length === 0) return null;

  shots.sort((a, b) => a.shotClock - b.shotClock);

  const toShot = (s: PlayerLastShots): Shot => {
    return {
      epps: s.epps,
      fouled: s.fouled,
      typeDefenderPlayerFoul: s.xFoul,
      typeDefenderPlayerMake: s.xMake,
      period: s.period,
      gameClock: s.gameClock,
      // We have some data coming back with weird values (like 59) so just add
      // some simple clamping to make sure all shot clock values are valid.
      shotClock: Math.min(24, Math.max(0, s.shotClock)),
      shotDist: s.distance,
      complexShotType: s.complexShotType,
      synergyUrl: s.URL,
      made: s.made,
      shotId: s.id,
    } as Shot;
  };

  const complexShotTypes = Object.keys(complexShotTypeMap)
    .filter((key) => key !== "post" && key !== "catchAndShootOnMove")
    .map((key) => {
      const cst = complexShotTypeMap[key];
      return { value: key, label: cst ? cst.label : "Unknown" };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const shotTypeGroup1 = [
    { label: "All Shots", value: "all" },
    { label: "All Made Shots", value: "made" },
    { label: "All Missed Shots", value: "miss" },
  ];

  const shotTypeGroup2 = [
    { label: "Layups", value: "layups" },
    { label: "NL2s", value: "nl2s" },
    { label: "Threes", value: "threes" },
  ];

  const byShotType = (s: PlayerLastShots) => {
    if (shotType === "all") {
      return true;
    } else if (shotType === "made") {
      return s.made;
    } else if (shotType === "miss") {
      return !s.made;
    } else if (shotType === "threes") {
      return s.isThree;
    } else if (shotType === "layups") {
      return s.shotType === "layup" && !s.isThree;
    } else if (shotType === "nl2s") {
      return s.shotType !== "layup" && !s.isThree;
    }
    return s.complexShotType === shotType;
  };

  const byGame = (s: PlayerLastShots) => {
    if (games === "lastGame") {
      return s.gameNum === 1;
    } else if (games === "lastTen") {
      return s.gameNum <= 10;
    }
    return true;
  };

  const filteredShots = shots.filter(byShotType).filter(byGame);
  const filteredOutShots = shots.filter(byShotType).filter((s) => !byGame(s));

  return (
    <Panel header="Shot Quality">
      {shots && (
        <div>
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              flexWrap: "wrap",
              marginBottom: 15,
            }}
          >
            <Form.Select
              style={{ width: "auto" }}
              value={games}
              onChange={(e) => setGames(e.target.value)}
            >
              {[
                { value: "lastGame", label: "Last Game" },
                { value: "lastTen", label: "Last 10 Games" },
                { value: "lastHundredTen", label: "Last 110 Games" },
              ]
                .sort()
                .map((g) => (
                  <option key={g.value} value={g.value}>
                    {g.label}
                  </option>
                ))}
            </Form.Select>
            <Form.Select
              style={{ width: "auto" }}
              value={shotType}
              onChange={(e) => setShotType(e.target.value)}
            >
              {shotTypeGroup1.map((p) => (
                <option key={p.value} value={p.value}>
                  {p.label}
                </option>
              ))}
              <optgroup label="Shot Location">
                {shotTypeGroup2.map((p) => (
                  <option key={p.value} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </optgroup>
              <optgroup label="Shot Type">
                {complexShotTypes.map((p) => (
                  <option key={p.value} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </optgroup>
            </Form.Select>
          </div>
          <ShotQuality
            shots={filteredShots.map(toShot)}
            filteredOutShots={filteredOutShots.map(toShot)}
            teamId={teamId || undefined}
          />
        </div>
      )}
    </Panel>
  );
}

function InjuriesPanel(props: { playerId: number }) {
  const { playerId } = props;

  const { data: injuries } = trpc.player.getPlayerInjuries.useQuery({
    playerId,
  });

  return (
    <Panel header="Injuries">
      {injuries && <PlayerInjuryTable data={injuries} />}
    </Panel>
  );
}

function SynergySeasonBoxScoresPanel(props: { playerId: number }) {
  const { playerId } = props;
  const [synergyBoxScoreTab, setSynergyBoxScoreTab] = useState(
    "synergyBoxScorePerPossession"
  );

  const { data: synergyBoxes } = trpc.player.getPlayerSynergyBoxes.useQuery({
    playerId,
  });

  if (synergyBoxes === undefined || synergyBoxes.length === 0) return null;

  const tabs = {
    onClick: (tabKey: string) => setSynergyBoxScoreTab(tabKey),
    active: synergyBoxScoreTab,
    tabs: {
      synergyBoxScorePerPossession: {
        label: "Synergy Stats Per 100 Possessions",
        content: (
          <PlayerSynergySeasonBox
            data={synergyBoxes}
            type={"per100Possessions"}
          />
        ),
      },
      synergyBoxScorePercentages: {
        label: "Distribution",
        content: (
          <PlayerSynergySeasonBox data={synergyBoxes} type={"percentages"} />
        ),
      },
      synergyBoxScoreTotals: {
        label: "Totals",
        content: <PlayerSynergySeasonBox data={synergyBoxes} type={"totals"} />,
      },
    },
  };

  return <Panel tabs={tabs}></Panel>;
}

function SpeedPanel(props: { playerId: number }) {
  const { playerId } = props;
  const [speedTab, setSpeedTab] = useState("byDate");

  const speedByDate = trpc.player.getSpeedByDate.useQuery({
    playerId: playerId,
  });

  const speed = trpc.player.getSpeedBySeason.useQuery({ playerId: playerId });

  const tabs = {
    onClick: (tabKey: string) => setSpeedTab(tabKey),
    active: speedTab,
    tabs: {
      byDate: {
        label: "Speed By Date",
        content: speedByDate.data ? (
          <PlayerSpeedByDateChart data={speedByDate.data} />
        ) : (
          <Spinner />
        ),
      },
      bySeason: {
        label: "By Season",
        content: speed.data ? (
          <PlayerSpeedTable data={speed.data} />
        ) : (
          <Spinner />
        ),
      },
    },
  };

  return <Panel tabs={tabs}></Panel>;
}

function PlayerLinksPanel(props: { playerId: number }) {
  const { playerId } = props;

  const { data: links } = trpc.playerLink.getPlayerLinks.useQuery({ playerId });

  return (
    <Panel header="Player Videos And Links">
      {links?.map((l, i) => {
        return (
          <div key={i}>
            <a href={l.url} target="_blank" rel="noreferrer">
              {l.name}
            </a>
          </div>
        );
      })}
      <p style={{ fontSize: ".7em", marginTop: "1em" }}>
        <Link to={"/player-links"}>Edit Player Links</Link>
      </p>
    </Panel>
  );
}
