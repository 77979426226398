import React, { useMemo, useContext } from "react";

import {
  Periods,
  complexShotTypeMap,
  simpleShotTypeLabelMap,
  Positions,
  shotLabels,
  secondSpectrumContestLevels,
} from "../../constants/AppConstants";
import { gameClockFormat } from "../../util/Format";
import { FilterForm } from "../query/FilterForm";
import AppContext from "../../../shared/AppContext";
import { TeamContext } from "../../TeamContext";
import { SSPlayerContext } from "../../PlayerContext";
import { NbaEaglePlayer } from "../../../shared/routers/RosterRouter";
import { ShotFilters } from "../../../shared/routers/ShotRouter";

const PERIOD_TIME_MAX = 720;
const DEF_DIST_MAX = 85;
const XPPS_MAX = 3;
const SHOT_DISTANCE_MAX = 94;
const SHOT_CLOCK_MAX = 24;
const DRIBBLES_BEFORE_MAX = 50;
const DISTANCE_FROM_HOOP_MAX = 60;

export function ShotFilterForm(props: {
  filters: ShotFilters;
  onFilterChange: (newFilterValues: ShotFilters) => void;
}) {
  const { filters, onFilterChange } = props;

  // Data to populate filters.
  const teams = useContext(TeamContext).teams;
  const players = useContext(SSPlayerContext);

  const playerSeasonTeamMap = useMemo(() => {
    if (!players) return;
    const ret: Record<string, Record<string, Set<string>>> = {};
    for (const player of players) {
      ret[player.playerId.toString()] = getSeasonTeamMap(player);
    }
    return ret;
  }, [players]);

  const filterPlayers = (p: NbaEaglePlayer, offense = true) => {
    const entry =
      playerSeasonTeamMap && playerSeasonTeamMap[p.playerId.toString()];
    if (!entry) return false;

    const seasons = filters.seasons;
    const teams = offense ? filters.offTeamIds : filters.defTeamIds;

    if (seasons?.length && teams?.length) {
      for (const season of seasons) {
        for (const team of teams) {
          const entryForSeason = entry[season];
          if (entryForSeason && entryForSeason.has(team)) {
            return true;
          }
        }
      }
      return false;
    } else if (seasons?.length) {
      return seasons.some((season) => Object.keys(entry).includes(season));
    } else if (teams?.length) {
      const allTeams = new Set(
        (function* () {
          for (const set of Object.values(entry)) yield* set;
        })()
      );
      return teams.some((team) => allTeams.has(team));
    }

    return true;
  };

  return (
    <FilterForm
      filters={filters}
      setFilters={onFilterChange}
      controls={[
        // Group 1 - Season/Period stuff.
        [
          // Row 1
          [
            {
              label: "Season",
              key: "seasons",
              type: "multiselect",
              options: AppContext.seasons.map((s) => {
                return { value: s.value.toString(), label: s.label };
              }),
            },
            {
              label: "Date",
              key: "fromDate",
              key2: "toDate",
              type: "daterange",
            },
          ],
          // Row 2
          [
            {
              label: "Period",
              key: "periods",
              type: "multiselect",
              options: Object.keys(Periods).map((p) => {
                return { value: p, label: Periods[parseInt(p)] || "" };
              }),
            },
            {
              label: "Period Time Remaining",
              key: "fromPeriodTime",
              key2: "toPeriodTime",
              type: "range",
              maxValue: PERIOD_TIME_MAX,
              stepSize: 1,
              fmt: (v1, v2) =>
                `${gameClockFormat(v1)} - ${gameClockFormat(v2)}`,
            },
          ],
          // Row 3
          [
            {
              label: "Is Playoffs",
              key: "isPlayoffs",
              type: "boolean",
            },
          ],
        ],
        // Group 2 Shot type stuff.
        [
          // Row 1
          [
            {
              label: "General Shot Type",
              key: "generalShotTypes",
              type: "multiselect",
              options: Object.keys(simpleShotTypeLabelMap).map((key) => {
                return {
                  value: key,
                  label: simpleShotTypeLabelMap[key] || "Unknown",
                };
              }),
            },
            {
              label: "Specific Shot Type",
              key: "specificShotTypes",
              type: "multiselect",
              options: Object.keys(complexShotTypeMap)
                .filter((key) => {
                  const cst = complexShotTypeMap[key];
                  return cst && cst.parent === undefined;
                })
                .map((key) => {
                  const cst = complexShotTypeMap[key];
                  return {
                    value: key,
                    label: cst ? cst.label : "Unknown",
                  };
                }),
            },
          ],
          // Row 2
          [
            {
              label: "C&S Direction",
              key: "directions",
              type: "multiselect",
              options: Object.entries(shotLabels.direction).map(
                ([key, val]) => {
                  return {
                    label: val,
                    value: key,
                  };
                }
              ),
            },
            {
              label: "Drive Direction",
              key: "driveDirections",
              type: "multiselect",
              options: Object.entries(shotLabels.drive_direction).map(
                ([key, val]) => {
                  return {
                    label: val,
                    value: key,
                  };
                }
              ),
            },
          ],
        ],
        // Group 3 - Offense Stuff
        [
          // Row 1
          [
            {
              label: "Off Team",
              key: "offTeamIds",
              type: "multiselect",
              options: teams.map((t) => {
                return {
                  label: `${t.teamcity} ${t.teamname}`,
                  value: t.teamid.toString(),
                };
              }),
            },
          ],
          // Row 2
          [
            {
              label: "Shooter",
              key: "shooterIds",
              type: "multiselect",
              options: players
                .filter((p) => filterPlayers(p, true))
                .map((p) => {
                  return {
                    label: p.player,
                    value: p.playerId.toString(),
                  };
                }),
            },
            {
              label: "Passer",
              key: "passerIds",
              type: "multiselect",
              options: players
                .filter((p) => filterPlayers(p, true))
                .map((p) => {
                  return {
                    label: p.player,
                    value: p.playerId.toString(),
                  };
                }),
            },
          ],
          // Row 3
          [
            {
              label: "Position (At Time)",
              key: "shooterPositions",
              type: "multiselect",
              options: Object.keys(Positions).map((p) => {
                return { value: p, label: Positions[parseInt(p)] || "" };
              }),
            },
            {
              label: "Position (Typical)",
              key: "shooterTypicalPositions",
              type: "multiselect",
              options: Object.keys(Positions).map((p) => {
                return { value: p, label: Positions[parseInt(p)] || "" };
              }),
            },
          ],
        ],
        // Group 4 - Defense Stuff
        [
          // Row 1
          [
            {
              label: "Def Team",
              key: "defTeamIds",
              type: "multiselect",
              options: teams.map((t) => {
                return {
                  label: `${t.teamcity} ${t.teamname}`,
                  value: t.teamid.toString(),
                };
              }),
            },
          ],
          // Row 2
          [
            {
              label: "Defender",
              key: "defenderIds",
              type: "multiselect",
              options: players
                .filter((p) => filterPlayers(p, false))
                .map((p) => {
                  return {
                    label: p.player,
                    value: p.playerId.toString(),
                  };
                }),
            },
            {
              label: "Defender Distance",
              key: "fromDefDist",
              key2: "toDefDist",
              type: "range",
              maxValue: DEF_DIST_MAX,
              stepSize: 0.1,
              fmt: (v1, v2) => `${v1}ft - ${v2}ft`,
            },
          ],
          // Row 3
          [
            {
              label: "Defender Position (At Time)",
              key: "defenderPositions",
              type: "multiselect",
              options: Object.keys(Positions).map((p) => {
                return { value: p, label: Positions[parseInt(p)] || "" };
              }),
            },
            {
              label: "Defender Position (Typical)",
              key: "defenderTypicalPositions",
              type: "multiselect",
              options: Object.keys(Positions).map((p) => {
                return { value: p, label: Positions[parseInt(p)] || "" };
              }),
            },
          ],
        ],
        // Group 5 - First set of sliders.
        [
          // Row 1
          [
            {
              label: "xPPS",
              key: "fromxpps",
              key2: "toxpps",
              type: "range",
              maxValue: XPPS_MAX,
              stepSize: 0.01,
              fmt: (v1, v2) => `${v1} - ${v2}`,
            },
            {
              label: "Shot Distance",
              key: "fromShotDistance",
              key2: "toShotDistance",
              type: "range",
              maxValue: SHOT_DISTANCE_MAX,
              stepSize: 0.1,
              fmt: (v1, v2) => `${v1}ft - ${v2}ft`,
            },
            {
              label: "Shot Clock",
              key: "fromShotClock",
              key2: "toShotClock",
              type: "range",
              maxValue: SHOT_CLOCK_MAX,
              stepSize: 0.1,
              fmt: (v1, v2) => `${v1}s - ${v2}s`,
            },
          ],
        ],
        // Group 6 - Second set of sliders.
        [
          // Row 1
          [
            {
              label: "Dribbles Before",
              key: "fromDribbles",
              key2: "toDribbles",
              type: "range",
              maxValue: DRIBBLES_BEFORE_MAX,
              stepSize: 1,
              fmt: (v1, v2) => `${v1} - ${v2}`,
            },
            {
              label: "Inches From Hoop Center",
              key: "fromDistanceFromHoopCenter",
              key2: "toDistanceFromHoopCenter",
              type: "range",
              maxValue: DISTANCE_FROM_HOOP_MAX,
              stepSize: 1,
              fmt: (v1, v2) => `${v1} - ${v2}`,
            },
            {
              label: "Contest Level",
              key: "contestLevel",
              type: "multiselect",
              options: Object.keys(secondSpectrumContestLevels).map((c) => {
                return {
                  value: c,
                  label:
                    secondSpectrumContestLevels[
                      c as keyof typeof secondSpectrumContestLevels
                    ],
                };
              }),
            },
          ],
        ],
        // Group 7 - First set of booleans.
        [
          // Row 1
          [
            {
              label: "3PT Shot",
              key: "isThree",
              type: "boolean",
            },
            {
              label: "Above the Break",
              key: "aboveTheBreak",
              type: "boolean",
            },
            {
              label: "Corner 3",
              key: "corner",
              type: "boolean",
            },
          ],
          // Row 2
          [
            {
              label: "Putback",
              key: "putback",
              type: "boolean",
            },
            {
              label: "Blocked Shot",
              key: "blocked",
              type: "boolean",
            },
            {
              label: "Fouled",
              key: "fouled",
              type: "boolean",
            },
          ],
        ],
        // Group 8 - Second set of booleans.
        [
          // Row 1
          [
            {
              label: "Transition",
              key: "transition",
              type: "boolean",
            },
            {
              label: "Left Side",
              key: "leftSide",
              type: "boolean",
            },
            {
              label: "After Oreb 20-24",
              key: "oreb2024",
              type: "boolean",
            },
          ],
          // Row 2
          [
            {
              label: "Made",
              key: "made",
              type: "boolean",
            },
            {
              label: "After Timeout",
              key: "afterTimeout",
              type: "boolean",
            },
            {
              type: "blank",
            },
          ],
        ],
      ]}
    />
  );
}

function getSeasonTeamMap(p: NbaEaglePlayer) {
  const map: Record<string, Set<string>> = {};
  const seasonTeamArr = p.seasonTeam.split(" ");
  for (const seasonTeam of seasonTeamArr) {
    const year = seasonTeam.split("_")[0] || "";
    const team = seasonTeam.split("_")[1] || "";
    const dataForYear = map[year];
    if (!dataForYear) {
      map[year] = new Set([team]);
    } else {
      dataForYear.add(team);
    }
  }
  return map;
}
