import React from "react";
import { Link } from "react-router-dom";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { PnrAggregate } from "../../../shared/routers/PnrRouter_2";
import {
  Highlights,
  pnrLabels,
  BHR_DEFENDER_COVERAGES,
  SCR_DEFENDER_COVERAGES,
  SCR_DEFENDER_DEPTH,
} from "../../constants/AppConstants";
import {
  dateFormat,
  decFormat2,
  pctFormat,
  seFormat2,
} from "../../util/Format";
import { VideoControl } from "../query/VideoControl";
import { PlayerTableCell, TeamTableCell } from "../core/TableCell";
import { reduceArrayToObject } from "../../../shared/util/Collections";

const BHR_DEFENDER_COVERAGES_MAP = reduceArrayToObject(
  BHR_DEFENDER_COVERAGES,
  (s) => s.value
);

const SCR_DEFENDER_COVERAGES_MAP = reduceArrayToObject(
  SCR_DEFENDER_COVERAGES,
  (s) => s.value
);

const SCR_DEFENDER_MAP = reduceArrayToObject(
  SCR_DEFENDER_DEPTH,
  (s) => s.value
);

const columnHelper = createColumnHelper<PnrAggregate>();

export function PnrQueryResultsTable(props: {
  data: PnrAggregate[];
  groupBy: string[];
  sorting?: SortingState;
  setSorting?: (sorting: SortingState) => void;
  onVideo: (data: PnrAggregate, groupBy: string[]) => void;
  columns: (keyof PnrAggregate)[];
  actionChanceLevel: string;
}) {
  const {
    data,
    groupBy,
    sorting,
    setSorting,
    onVideo,
    columns: selectedColumns,
    actionChanceLevel,
  } = props;

  const columns = React.useMemo(() => {
    const minPicks = Math.min(...data.map((d) => d.numPicks));
    const maxPicks = Math.max(...data.map((d) => d.numPicks));

    const isActionLevel = actionChanceLevel === "action";

    let g = 0;
    return [
      columnHelper.accessor("season", {
        header: () => "Season",
        meta: { group: g++ },
      }),
      columnHelper.accessor("playoffs", {
        header: () => "Playoffs",
        cell: (info) => (info.getValue() ? "Playoffs" : "Regular Season"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("offTeam", {
        header: () => "Offense Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.offTeamId}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("defTeam", {
        header: () => "Defense Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.defTeamId}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ballhandler", {
        header: () => "Ballhandler",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.ballhandlerId}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("screener", {
        header: () => "Screener",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue() || undefined}
            id={info.row.original.screenerId || undefined}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("screenerDefender", {
        header: () => "Screener Defender",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue() || undefined}
            id={info.row.original.screenerId || undefined}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ballhandlerDefender", {
        header: () => "Ballhandler Defender",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.ballhandlerDefenderId}
          />
        ),
        meta: { group: g },
      }),
      // columnHelper.accessor("screenerDefender", {
      //   header: () => "Screener Defender",
      //   cell: (info) => (
      //     <PlayerTableCell
      //       name={info.getValue()}
      //       id={info.row.original.screenerDefenderId}
      //     />
      //   ),
      //   meta: { group: g++ },
      // }),
      columnHelper.accessor("type", {
        header: () => "Type",
        cell: (info) => {
          const val = info.getValue();
          return val === "pnr"
            ? "PNR"
            : val === "dho"
            ? "DHO"
            : val === "fake_dho"
            ? "Fake DHO"
            : "Unknown";
        },
        meta: { group: g },
      }),
      columnHelper.accessor("bhrDefenderCoverage", {
        header: () => "Ballhandler Defender Coverage",
        cell: (info) => {
          const val = info.getValue();
          return val ? BHR_DEFENDER_COVERAGES_MAP[val as string] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("scrDefenderCoverage", {
        header: () => "Screener Defender Coverage",
        cell: (info) => {
          const val = info.getValue();
          return val ? SCR_DEFENDER_COVERAGES_MAP[val as string] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("scrDefenderDepth", {
        header: () => "Screener Defender Depth",
        cell: (info) => {
          const val = info.getValue();
          return val ? SCR_DEFENDER_MAP[val as string] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => {
          const val = info.getValue();
          return val ? dateFormat(new Date(val.substring(0, 10))) : "";
        },
        meta: { group: g },
      }),
      columnHelper.accessor("gameString", {
        header: () => "Game",
        cell: (info) => (
          <Link to={`/game/${info.row.original.gameId}`}>
            {info.getValue()}
          </Link>
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("period", {
        header: () => "Period",
        meta: { group: g++ },
      }),
      columnHelper.accessor("direction", {
        header: () => "Direction",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.direction[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("rollPop", {
        header: () => "Roll / Pop",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.roll_pop[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("pickSlip", {
        header: () => "Pick / Slip",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.scr_type[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("locationType", {
        header: () => "Location",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.location_type[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("numPerimSpacers", {
        header: () => "# Perim Spacers",
        cell: (info) => info.getValue(),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("numRimSpacers", {
        header: () => "# Rim Spacers",
        cell: (info) => info.getValue(),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("horns", {
        header: () => "Horns",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("emptySide", {
        header: () => "Empty Side",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("fiveOut", {
        header: () => "5-Out",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("horns", {
        header: () => "Horns",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      // columnHelper.accessor("double", {
      //   header: () => "Double Team",
      //   cell: (info) => (info.getValue() ? "Yes" : "No"),
      //   meta: { group: g++ },
      // }),
      columnHelper.accessor("numPicks", {
        header: () => "# PNRs",
        cell: (info) => info.getValue().toLocaleString(),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: [minPicks, maxPicks],
        },
      }),
      columnHelper.accessor(isActionLevel ? "xppp_action" : "xppp", {
        header: () => "xPPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.9, 1.2],
          heatmap: true,
        },
      }),
      columnHelper.accessor(isActionLevel ? "stderr_action" : "stderr", {
        header: () => "SE",
        cell: (info) => seFormat2(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor(isActionLevel ? "xpps_action" : "xpps", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [1, 1.2],
        },
      }),
      columnHelper.accessor(isActionLevel ? "layupPct_action" : "layupPct", {
        header: () => "Layup %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor(isActionLevel ? "threePct_action" : "threePct", {
        header: () => "3PA %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor(
        isActionLevel ? "nonLayupTwoPct_action" : "nonLayupTwoPct",
        {
          header: () => "NL2 %",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
          },
        }
      ),
      columnHelper.accessor(
        isActionLevel ? "turnoverPct_action" : "turnoverPct",
        {
          header: () => "TO %",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            group: g,
          },
        }
      ),
      columnHelper.accessor(isActionLevel ? "orbPct_action" : "orbPct", {
        header: () => "ORB %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor(isActionLevel ? "xOrbPct_action" : "xOrbPct", {
        header: () => "xORB %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("pnrPassPct", {
        header: () => "Pass %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("pnrPassScrPct", {
        header: () => "Pass Scr %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("pnrAstOppPct", {
        header: () => "Ast Opp %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("pnrAstScrPct", {
        header: () => "Ast Scr %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("pnrToPct", {
        header: () => "TO %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor(isActionLevel ? "ppp_action" : "ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.9, 1.2],
        },
      }),
      columnHelper.accessor(isActionLevel ? "pps_action" : "pps", {
        header: () => "PPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [1, 1.2],
        },
      }),
      columnHelper.accessor(
        isActionLevel ? "layupFgPct_action" : "layupFgPct",
        {
          header: () => "Layup FG%",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: [0.54, 0.63],
          },
        }
      ),
      columnHelper.accessor(
        isActionLevel ? "threeFgPct_action" : "threeFgPct",
        {
          header: () => "Three FG%",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: [0.3, 0.4],
          },
        }
      ),
      columnHelper.accessor(
        isActionLevel ? "nonLayupTwoFgPct_action" : "nonLayupTwoFgPct",
        {
          header: () => "NL2 FG%",
          cell: (info) => pctFormat(info.getValue()),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: [0.4, 0.47],
          },
        }
      ),
      columnHelper.accessor("hasVideo", {
        header: () => "Video",
        cell: (info) => {
          return info.getValue() ? (
            <VideoControl
              data={info.row.original}
              onVideo={(d) => onVideo(d, groupBy)}
            />
          ) : (
            ""
          );
        },
        meta: { group: g++ },
      }),
    ];
  }, [data, actionChanceLevel, onVideo, groupBy]);

  const hiddenColumns = {
    season: groupBy.includes("season"),
    playoffs: groupBy.includes("playoffs"),
    period: groupBy.includes("period"),
    gameDate: groupBy.includes("gameId"),
    gameString: groupBy.includes("gameId"),
    offTeam: groupBy.includes("offTeamId"),
    defTeam: groupBy.includes("defTeamId"),
    screener: groupBy.includes("screenerId"),
    ballhandler: groupBy.includes("ballhandlerId"),
    screenerDefender: groupBy.includes("screenerDefenderId"),
    ballhandlerDefender: groupBy.includes("ballhandlerDefenderId"),
    type: groupBy.includes("type"),
    screenType: groupBy.includes("screenType"),
    direction: groupBy.includes("direction"),
    coverage: groupBy.includes("coverage"),
    scrDefenderDepth: groupBy.includes("scrDefenderDepth"),
    floorSide: groupBy.includes("floorSide"),
    numPerimSpacers: groupBy.includes("numPerimSpacers"),
    numRimSpacers: groupBy.includes("numRimSpacers"),
    // double: groupBy.includes("double"),
    horns: groupBy.includes("horns"),
    drag: groupBy.includes("drag"),
    emptySide: groupBy.includes("emptySide"),
    fiveOut: groupBy.includes("fiveOut"),
    dunkerFilled: groupBy.includes("dunkerFilled"),
    rollPop: groupBy.includes("rollPop"),
    pickSlip: groupBy.includes("pickSlip"),
    locationType: groupBy.includes("locationType"),
    // Selectable columns:
    numPicks: selectedColumns.includes("numPicks"),
    xppp: selectedColumns.includes("xppp"),
    xppp_action: selectedColumns.includes("xppp"),
    xpps: selectedColumns.includes("xpps"),
    xpps_action: selectedColumns.includes("xpps"),
    stderr: selectedColumns.includes("stderr"),
    stderr_action: selectedColumns.includes("stderr"),
    ppp: selectedColumns.includes("ppp"),
    ppp_action: selectedColumns.includes("ppp"),
    pps: selectedColumns.includes("pps"),
    pps_action: selectedColumns.includes("pps"),
    layupPct: selectedColumns.includes("layupPct"),
    layupPct_action: selectedColumns.includes("layupPct"),
    threePct: selectedColumns.includes("threePct"),
    threePct_action: selectedColumns.includes("threePct"),
    nonLayupTwoPct: selectedColumns.includes("nonLayupTwoPct"),
    nonLayupTwoPct_action: selectedColumns.includes("nonLayupTwoPct"),
    turnoverPct: selectedColumns.includes("turnoverPct"),
    turnoverPct_action: selectedColumns.includes("turnoverPct"),
    orbPct: selectedColumns.includes("orbPct"),
    orbPct_action: selectedColumns.includes("orbPct"),
    xOrbPct: selectedColumns.includes("xOrbPct"),
    xOrbPct_action: selectedColumns.includes("xOrbPct"),
    pnrPassPct: selectedColumns.includes("pnrPassPct"),
    pnrPassScrPct: selectedColumns.includes("pnrPassScrPct"),
    pnrAstOppPct: selectedColumns.includes("pnrAstOppPct"),
    pnrAstScrPct: selectedColumns.includes("pnrAstScrPct"),
    pnrToPct: selectedColumns.includes("pnrToPct"),
    layupFgPct: selectedColumns.includes("layupFgPct"),
    layupFgPct_action: selectedColumns.includes("layupFgPct"),
    threeFgPct: selectedColumns.includes("threeFgPct"),
    threeFgPct_action: selectedColumns.includes("threeFgPct"),
    nonLayupTwoFgPct: selectedColumns.includes("nonLayupTwoFgPct"),
    nonLayupTwoFgPct_action: selectedColumns.includes("nonLayupTwoFgPct"),
  };

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      hiddenColumns={hiddenColumns}
      setSorting={setSorting}
      virtualScroll={true}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
