import React from "react";
import { Spinner as BootstrapSpinner } from "react-bootstrap";

export function Spinner(props: { message?: string }) {
  const { message } = props;
  return (
    <div
      style={{
        textAlign: "center",
        verticalAlign: "middle",
        marginRight: 12,
      }}
    >
      <BootstrapSpinner variant="primary" animation="border" role="status" />
      <span>{message}</span>
    </div>
  );
}
