import React from "react";
import { Form } from "react-bootstrap";

import { DeleteButton } from "../core/DeleteButton";
import { BooleanInput } from "../core/BooleanInput";
import { NumberInput } from "../core/NumberInput";
import { MultiSelect } from "../core/MultiSelect";
import { groupBy } from "../../../shared/util/Collections";

export type DynamicFilterType = "boolean" | "multiselect" | "number";

export function DynamicFilter(props: {
  options: {
    value: string;
    label: string;
    type?: DynamicFilterType;
    options?: string[];
    group?: string;
  }[];
  values?: string[];
  filterKey: string;
  onChangeFilterKey: (newKey: string) => void;
  value: unknown;
  onRemove: () => void;
  onChange: (val: unknown) => void;
  showRemove: boolean;
}) {
  const {
    options,
    onChangeFilterKey,
    filterKey,
    value,
    onRemove,
    onChange,
    showRemove,
  } = props;
  let valuePicker: undefined | JSX.Element;

  const selectedOption = options.find((o) => o.value === filterKey);

  const groupedOptions = groupBy(options, (o) => o.group || "Other");

  if (selectedOption && selectedOption.type === "boolean") {
    valuePicker = (
      <BooleanInput
        name={filterKey}
        onChange={onChange}
        value={value as boolean | undefined}
      />
    );
  } else if (selectedOption && selectedOption.type === "number") {
    valuePicker = (
      <NumberInput
        onChange={onChange}
        value={value as { gte?: string; lte?: string; eq?: string }}
      />
    );
  } else if (selectedOption && selectedOption.type === "multiselect") {
    valuePicker = (
      <MultiSelect
        values={(selectedOption.options || []).map((o) => {
          return { value: o, label: o };
        })}
        selected={(value as string[]) || []}
        onChange={onChange}
      />
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <div style={{ width: 320 }}>
        <Form.Select
          style={{ width: "auto", display: "inline-block" }}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onChangeFilterKey(e.target.value)
          }
          value={filterKey}
        >
          {Object.entries(groupedOptions).map(([group, options]) => {
            return (
              <optgroup key={group} label={group}>
                {options.map((o) => {
                  return (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </Form.Select>
        <div style={{ marginTop: 8 }}>{valuePicker}</div>
      </div>
      <div>{showRemove && <DeleteButton onClick={onRemove} />}</div>
    </div>
  );
}
